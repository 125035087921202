<template>
    <div class="consulting">
        <div class="centerBody">
            <h2 class="title"><span>互联网+教育</span>把老师送到您身边</h2>
            <ul class="nav_label">
                <li>线上直播+录播</li>
                <li>微信互动领学</li>
                <li>班主任督学</li>
                <li>全程老师跟进</li>
            </ul>
            <p class="tips_p">因为我们专注，所以我们更专业！</p>
            <ul class="menu_list">
                <li v-for="(item, index) in dataList" :key="index">
                    <img :src="setImg(item.url)" alt="">
                    <p class="menu_title">{{ item.title }}</p>
                    <p class="menu_info" v-html="item.info"></p>
                </li>
            </ul>
            <div class="button_list">
                <p @click="useCustomerService">咨询老师</p>
                <p @click="useCustomerService" class="red">我要学习</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive } from "vue";
import { useCustomerService } from '@/api/common.js'

const dataList = reactive([
    {
        url: '1.png',
        title: '线上教学',
        info: '实力师资，每个人都<br>学得会记得住'
    },
    {
        url: '2.png',
        title: '直播特训',
        info: '直播录播结合教学<br>效果看得见'
    },
    {
        url: '3.png',
        title: 'APP学习',
        info: '手机APP同步学习<br>碎片化时间利用'
    },
    {
        url: '4.png',
        title: '班主任督学',
        info: '班主任小班管理<br>克服懒惰'
    },
    {
        url: '5.png',
        title: '微信领学',
        info: '微信平台<br>每日一练<br>随时学习'
    },
    {
        url: '6.png',
        title: 'AI智能题库',
        info: '大数据智能题库<br>精准预测考题走向'
    },
])
const setImg = (url) => {
    return require('@/assets/home/teacher_list/' + url)
}
</script>

<style scoped lang="scss">
.consulting {
    height: 823px;
    width: 100%;
    overflow: hidden;
    background: url('@/assets/home/info_bgc.png') no-repeat;
    background-size: 100% 100%;
    margin-bottom: 15px;
}

.centerBody {
    width: 1220px;
    margin-left: 50%;
    // height: 3400px;
    transform: translate(-50%);

    .title {
        margin-top: 60px;
        text-align: center;
        font-size: 40px;

        span {
            color: #db1d25;
        }
    }

    .nav_label {
        display: flex;
        justify-content: center;

        li {
            padding: 8px 10px;
            box-sizing: border-box;
            border: 1px solid #666;
            color: #666;
            font-size: 16px;
            margin: 15px 5px 5px 0;
        }
    }

    .tips_p {
        font-size: 24px;
        margin-top: 10px;
        text-align: center;
    }

    .menu_list {
        width: 1010px;
        display: flex;
        flex-wrap: wrap;
        margin-left: 50%;
        transform: translateX(-50%);

        li {
            width: 330px;
            text-align: center;
            margin-top: 47px;
            vertical-align: top;

            img {}

            .menu_title {
                font-size: 24px;
                margin: 10px 0;
            }

            .menu_info {}
        }
    }

    .button_list {
        width: 100%;
        display: flex;
        justify-content: center;
        p {
            // display: inline-block;
            width: 156px;
            height: 48px;
            line-height: 48px;
            border: 1px solid #db1d25;
            border-radius: 4px;
            font-size: 20px;
            text-align: center;
            margin: 45px 30px 0;
            transition: all 0.5s;
            color: #db1d25;
            cursor: pointer;
        }
        .red {
            background-color: #db1d25;
            color: #fff;
        }
        p:hover {
            margin-top: 35px;
        }
    }
}
</style>