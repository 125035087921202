<template>
  <div class="high-end-class">
    <!-- <div class="use_title_class"></div> -->
    <use-sub-title title="个性化学习" subTitle="深度伴学，战则必胜"></use-sub-title>
    <div class="center">
      <div class="left_body">
        <!-- <img src="@/assets/money_bgc.png" alt=""> -->
        <div class="header">
          <h1>VIP无忧班</h1>
          <p>制定课程 全程辅导</p>
        </div>
        <div class="contentTop">
          <div class="allSubCont">
            <p>
              ￥<span>15999</span>元/全科
            </p>
            <del>原价 19999 元</del>
          </div>

        </div>
        <ul class="gwy_body">
          <button class="submit" @click="shopDetail(false)">立即购买</button>
        </ul>
      </div>
      <div class="right_body">
        <div class="right_li">
          <div class="right_title">
            <h2>课程优势</h2>
          </div>
          <div class="right_body_c">
            <ul>
              <li class="">
                <h3><span>1</span>制定学习计划</h3>
                <p>入班后进行入学测评，班主任依据学员入学测评结果等结合实际情况，制定专属学习计划</p>
              </li>
              <li class="">
                <h3><span>2</span>跟踪学习进度</h3>
                <p>根据学员学习情况不断优化调整计划内容，确保为每一位学员提供个性化和专业化的学习支持</p>
              </li>
              <li class="">
                <h3><span>3</span>在线答疑</h3>
                <p>答疑老师均为知识丰富的学科老师，为学员及时解决学习过程中遇到的痛点和难点，大幅度提升备考效率</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="right_li">
          <div class="right_title">
            <h2>保障服务</h2>
          </div>
          <div class="right_body_c">
            <ul>
              <li class="">
                <h3><span>1</span>签署服务协议</h3>
                <p>双方签署服务协议，最大程度保障学员权益，一次未过，次年免费重修学习，让每一位学员学习更加安心，无任何后顾之忧</p>
              </li>
              <li class="">
                <h3><span>2</span>一站式伴学</h3>
                <p>打造了从入学测评、计划制定到督学、上课提醒、实时答疑、筛选岗位、报岗协助等一站式伴学</p>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="right_l">
          <div class="suchengCon1">
            <div class="top_conent">
              <h3>课程优势</h3>
              <div class="yc_content">
                <div class="yc_left">
                  <p>入班后进行入学测评，班主任依据学员入学测评结果等结合实际情况，制定专属学习计划</p>
                  <p>跟踪学员学习进度，并根据学员学习情况不断优化调整计划内容，确保为每一位学员提供个性化和专业化的学习支持</p>
                  <p>答疑老师均为知识丰富的学科老师，为学员及时解决学习过程中遇到的痛点和难点，大幅度提升备考效率</p>
                </div>
              </div>
            </div>
            <div class="bottom_content">
              <h3>保障服务</h3>
              <div class="yc_content">
                <div class="yc_left">
                  <p>双方签署服务协议，最大程度保障学员权益，一次未过，次年免费重修学习，让每一位学员学习更加安心，无任何后顾之忧</p>
                  <p>打造了从入学测评、计划制定到督学、上课提醒、实时答疑、筛选岗位、报岗协助等一站式伴学</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right_r">
          <div class="ggw_price">
            <div class="ggw_price-now">
              <strong>15999</strong>
              <span>元/全科</span>
            </div>
            <div class="ggw_price-pre"><del>原价19999</del>元</div>
            <div class="button_list">
              <button class="submit" @click="shopDetail(false)">立即购买</button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  __name: "high-end-class"
}
</script>

<style lang="scss" scoped>
.high-end-class {
  width: 1150px;
  height: 680px;
  margin: 0 auto;

  .center {
    padding: 40px 0;
    background: linear-gradient(to right, #fdf3cc, #fbe8ba, #f9d99f);
    display: flex;
    margin-top: -30px;

    .left {
      width: 260px;
      overflow: hidden;

      // border: 1px solid #dc3732;
      // background: #fcf9fc;
      background: rgba(237, 236, 237, 0.5);

      img {
        width: 100%;
      }

      .header {
        width: 400px;
        height: 140px;
        // background: #ecc94a;
        background: #de9d3a;
        // background: #dc3732;
        color: #ffffff;

        h2 {
          text-align: center;
          border-bottom: 1px solid #ffffff;
          width: 350px;
          margin: 0 auto;
          height: 57px;
          padding-top: 21px;
          font-size: 32px;
        }

        p {
          text-align: center;
          padding-top: 10px;
          font-size: 32px !important;
        }
      }



      .contentBottom {
        display: flex;
        justify-content: space-around;
        margin: 10px 30px;

        li {
          max-width: 175px;
          text-align: center;

          p {
            font-size: 22px;
            color: #dc3732;
            font-weight: bold;
          }

          del {
            font-size: 16px;
            color: #666666;
          }
        }
      }



      .submit:hover {
        background: #ff6e49;
      }

      .gwy_body {
        // display: flex;
        margin: 30px 0 0 126px;

        li {
          margin: 0;
          // max-width: 175px;
          margin-right: 18px;
          margin-bottom: 20px;
          // text-align: center;

          p {
            font-size: 22px;
            color: #dc3732;
            font-weight: bold;

            span {
              display: inline-block;
              width: 18px;
              height: 18px;
              background: #405aa5;
              // border-radius: 50%;
              // transform: translateY(2px);
            }
          }

          del {
            font-size: 16px;
            color: #666666;
          }
        }
      }
    }

    .right {
      padding-top: 30px;
      width: calc(100% - 260px);
      display: flex;
      justify-content: space-between;
      background: #fcf8f1;

      .right_l {
        width: 65%;
        height: 100%;
      }

      .right_r {
        width: 30%;
        height: 100%;
      }

      li {
        width: 200px;
        height: 420px;

        .vip-title {
          text-align: center;
          height: 40px;
          width: 150px;
          margin: 0 auto;
          // border-bottom: 3px solid#dc3732;
          border-bottom: 3px solid #de9d3a;
          font-size: 24px;
          font-weight: bold;
          padding-top: 15px;
        }

        span {
          display: inline-block;
          width: 150px;
          margin: 0 auto;
          padding-left: 30px;
          margin-top: 20px;
          font-size: 16px;
          line-height: 26px;
        }
      }

      li:nth-child(even) {
        background: #efefef;
      }
    }
  }
}

.suchengCon1 {
  padding: 0px 0 10px 40px;

  .suchengTit {
    color: #000000;
    font-weight: Bold;
    font-size: 28px;
    text-align: left;
    line-height: 48px;
  }

  .sucheng {
    margin-top: -10px;
    margin-bottom: 10px;
  }

  .top_conent {
    margin-bottom: 30px;
  }

  .bottom_content {

    align-items: center;
  }

  .top_conent,
  .bottom_content {
    display: flex;
    align-items: center;

    h3 {
      writing-mode: vertical-rl;
      margin-right: 10px;
    }
  }

  .yc_content {
    // background: red;
    display: flex;
    // height: 100px;



    span {
      font-weight: bold;
    }

    .yc_left {
      // float: left;
    }

    .yc_right {
      float: right;
    }

    .yc_left,
    .yc_right {
      border-left: 2px solid rgba(237, 215, 182, 1);
    }
  }
}

.yc_left>p,
.yc_right>p {
  font-size: 17px;
  color: #000000;
  line-height: 1.3;
  position: relative;
  padding-left: 16px;
  margin-bottom: 10px;
}

.yc_left>p::after,
.yc_right>p::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(237, 215, 182, 1);
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
}

.ggw_price {
  margin-top: 52px;
}

.ggw_price-now {
  font-size: 18px;
  font-weight: 400;
  color: #EC634D;

  span {
    // color: #000000;
    font-weight: bold;
    display: inline-block;
    font-size: 16px;
  }

  strong {
    color: rgba(255, 28, 12, 1);
    font-size: 39px;
  }
}

.ggw_price-pre {
  color: #000000;
  font-size: 14px;
  display: inline-block;
  vertical-align: bottom;
  margin-top: 15px;
  margin-left: 30px;
}

.button_list {
  margin-top: 10px;
}

.submit {
  width: 160px;
  height: 48px;
  // background: #dc3732;
  background: linear-gradient(to right, #f5c35d, #f3ad50, #f1a24a);
  color: #ffffff;
  border-radius: 24px;
  font-size: 19px;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  margin: 20px;
}

.white_submit {
  background: #fff;
  color: #000;
  border: 1px solid #ccc;
}



.left_body {
  color: #f0953e;
  text-align: center;
  width: 25%;
  margin-top: 20px;
  .header {
    margin-bottom: 10px;
    margin-top: 14px;
    h1 {
      font-size: 38px;
    }
    p {
      font-size: 20px;
    }
  }
  .contentTop {
    margin: 0 auto;
    height: 90px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;

    .allSubCont {
      margin-left: -10px;
      font-size: 30px !important;
      width: 100%;
      text-align: center;

      p {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: -12px;

        span {
          font-size: 38px;
        }
      }

      del {
        color: #666666;
        font-size: 18px;
        padding-left: 10px;
      }
    }
  }
}

.right_body {
  width: 75%;
  display: flex;
  .right_li {
    width: 50%;
    border-radius: 12px;
    overflow: hidden;
    background: #fffcf8;
    margin-right: 15px;
    .right_title {
      background: url('@/assets/right_title_bgc.png') no-repeat;
      background-size: 100% 100%;
      padding-left: 40px;
      color: #fff;
    }
    .right_body_c {
      ul {
        padding: 15px;
        li {
          padding: 10px 15px;
          h3 {
            margin-bottom: 5px;
            span {
              color: #fff;
              display: inline-block;
              width: 24px;
              height: 24px;
              line-height: 24px;
              border-radius: 5px;
              background: #e57051;
              text-align: center;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

</style>