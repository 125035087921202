export const navList = [
    {
        title: '首页',
        path: '/',
        name: 'home',
    },
    
    {
        title: '国考公务员',
        path: '/country',
        name: 'country',
    },
    {
        title: '省考公务员',
        path: '/province',
        name: 'province',
    },
    {
        title: '事业编考试',
        path: '/career',
        name: 'career',
    },
    // {
    //     title: '教师编考试',
    //     path: '/teacher',
    // },
    // {
    //     title: '军队文职',
    //     path: '/troops',
    //     name: 'troops',
    // },
    {
        title: '联系我们',
        path: '/contact',
        name: 'contact',
    }, 
    {
        title: '在线商城',
        path: '/onlineShop',
        name: 'onlineShop',
    },
]

// 企业聚焦
export const enterprise = {
    titleList: [
        {
            title: '金师网持教育本心 更重课程研发',
            params: 'gongan',
        },
        {
            title: '教编含金量',
            params: 'hanjin',
        },
    ],
    subNav: [
        {
            subTitle: '企业聚集',
            children: [
                {
                    title: '金师网教学质量深受学员认可',
                    params: 'teachApprove',
                },
                {
                    title: '课程力求高端新颖',
                    params: 'teachWeb',
                },
            ]
        },
        {
            subTitle: '报名指导',
            children: [
                {
                    title: '新手入门',
                    params: 'newHand',
                },
                {
                    title: '资格复查',
                    params: 'check',
                },
                {
                    title: '考试大纲',
                    params: 'outline',
                },
                {
                    title: '报考条件',
                    params: 'condition',
                },
                {
                    title: '缴费确认',
                    params: 'pay',
                },
            ]
        }
    ]
}

// 报名指导
export const enrollList = {
    titleList: [
        {
            title: '2024联考考场的安排',
            params: 'gongan',
        },
        {
            title: '2024事业单位考试的安排',
            params: 'gongan',
        },
    ],
    subNav: [
        {
            subTitle: '考试研究',
            children: [
                {
                    title: '省考行测备考策略',
                    params: 'provinceStrategy',
                },
                {
                    title: '2025联考申论冲刺的策略',
                    params: 'spurtStrategy',
                },
            ]
        },
        {
            subTitle: '通关必看',
            children: [
                {
                    title: '2025申论的学习方案',
                    params: 'studyScheme',
                },
                {
                    title: '2025联考行测考前七天冲刺',
                    params: 'sevenSpurt',
                },
                {
                    title: '备考',
                    params: 'forReference',
                },
            ]
        }
    ]
}
// export const enrollList = [
//     {
//         title: '新手入门',
//         params: 'newHand',
//     },
//     {
//         title: '资格复查',
//         params: 'check',
//     },
//     {
//         title: '考试大纲',
//         params: 'outline',
//     },
//     {
//         title: '报考条件',
//         params: 'condition',
//     },
//     {
//         title: '缴费确认',
//         params: 'pay',
//     },
// ]