<template>
  <div class="crowd">
    <use-sub-title title="课程适用人群" subTitle="对应课程 更快上岸" lineWidth="130px"></use-sub-title>
    <ul class="crowd_body">
      <li v-for="(item, index) in dataList" :key="index">
        <div class="top">
          <img :src="require(`@/assets/home/crowd/${item.img_url}`)" alt="">
          <!-- <img :src="require(`@/assets/zhuanti/syb/${item.icon}`)" alt=""> -->
          <p class="title">
            {{ item.title }}
          </p>
        </div>
        <p class="sub_title">
          {{ item.subTitle }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { reactive } from 'vue';


const dataList = reactive([
  {
    title: '应届生',
    subTitle: '大学毕业实现未来更好的职业发展',
    img_url: 'two.png'
  },
  {
    title: '公考小白',
    subTitle: '初次备考经验不足、没有学习技巧',
    img_url: 'one.png'
  },
  {
    title: '职场转岗',
    subTitle: '职业发展不稳定，薪资待遇不好',
    img_url: 'three.png'
  },
  {
    title: '全职妈妈',
    subTitle: '谋求孩子学习，生活和工作的最佳平衡',
    img_url: 'four.png'
  },
])
</script>

<style lang="scss" scoped>
.crowd {
  width: 100%;
  height: 530px;
  background: url(../../assets/home/crowd/srowd_bgc.png) no-repeat;
  background-size: 100% 100%;
}
.crowd_body {
  width: 65%;
  margin-left: 17.5%;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  li {
    width: 22.8%;
    height: 200px;
    position: relative;
    .top {
      height: 100%;
      img {
        width: 100%;
        height: calc(100% - 46px);
      }
      .title {
        width: 100%;
        position: absolute;
        bottom: 0;
        color: #fff;
        text-align: center;
        font-size: 24px;
        height: 46px;
        line-height: 46px;
        background: #ee3e40;
        border-radius: 0 0 15px 15px;
      }
    }

    .sub_title {
      font-size: 18px;
      width: 70%;
      margin-top: 15px;
      margin-left: 15%;
      font-weight: 400;
      line-height: 26px;
      color: #952a20;
    }
  }
}
</style>