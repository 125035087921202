<template>
  <div class="test-book" @click="useCustomerService">
    <div v-if="is_gwy == 1">
      <img src="@/assets/image/gwy-bookOne.png" alt="">
      <img src="@/assets/image/gwy-bookTwo.png" alt="">
    </div>
    <div v-if="is_gwy == 2">
      <img src="@/assets/image/syb-bookOne.jpg" alt="">
      <img src="@/assets/image/syb-bookTwo.jpg" alt="">
    </div>
    <div v-if="is_gwy == 3">
      <!-- <img src="@/assets/image/bookOne.jpg" alt=""> -->
      <img src="@/assets/image/bookTwo.jpg" alt="">
    </div>
  </div>
</template>

<script setup>
import { useCustomerService } from '@/api/common';
import { computed } from 'vue';

const is_gwy = computed(() => {
  let url = window.location.pathname;
  if (url == '/country' || url == '/province') {
    return 1
  }else if (url == '/career') {
    return 2;
  }else {
    return 3;
  }
})
</script>

<style lang="scss" scoped>
.test-book {
    height: 968px;
    img {
        width: 100%;
        height: 484px;
        cursor: pointer;
    }
}
</style>