<template>
  <div class="high-end-class">
    <use-sub-title
      title="快速上岸 省时省心"
      subTitle="用心服务 制定课程 学习更高效"
    ></use-sub-title>
    <div class="center">
      <div class="left">
        <div class="header">
          <!-- <h2 v-if="route.name == 'country'">国家公务员考试</h2>
          <h2 v-else-if="route.name == 'province'">省级公务员考试</h2> -->
          <h2>VIP 无忧班</h2>
          <h3>制定课程 全程辅导</h3>
        </div>
        <div class="contentTop">
          <div class="allSubCont">
            <p>
              ￥<span>{{ props.allSubject }}</span
              >元/全科
            </p>
            <del>原价 {{ props.oldAllSubject }}元</del>
          </div>
          
        </div>
        <ul class="gwy_body" >
          <button class="submit" @click="shopDetail(false)">立即购买</button>
          <!-- <li><p><span></span>《申论》</p></li>
          <li><p><span></span>《行政职业能力测验》</p></li> -->
        </ul>
        <!-- <ul class="contentBottom" v-else>
          <li>
            <p>笔试班</p>
            <p>{{ props.penNum }}元/科</p>
            <del>{{ props.oldPenNum }}元/科</del>
          </li>
          <li>
            <p>面试班</p>
            <p>{{ props.anOralQuiz }}元/科</p>
            <del>{{ props.oldAnOralQuiz }}元/科</del>
          </li>
        </ul> -->
      </div>
      <ul class="right">
        <li>
          <p class="vip-title">课程特点</p>
          <span>根据学员情况,打造合适课程。</span>
          <span>高清课程随时看，学习方便，省时高效。</span>
          <span>精选考点，通俗讲解强化记忆。</span>
          <span>包含网校一切资料，次年免费学习</span>
        </li>
        <li>
          <p class="vip-title">课时及资料</p>
          <span>课时：根据学员STA测评情况制定。</span>
          <span>内部资料：核心考试重点内容。</span>
          <span>答疑：专属答疑，班主任及时对接陪伴</span>
          <span>考前密训：考前根据信息，及时发送重点资料帮助学员通过。</span>
        </li>
        <li>
          <p class="vip-title">专属老师</p>
          <span>老师在线答疑</span>
          <span>工资与学员通过挂钩全心服务。</span>
          <span>班主任全程陪伴学习答疑服务，及时高效</span>
        </li>
        <li>
          <p class="vip-title">保障服务</p>
          <span>签订学习法律相关协议。</span>
          <span>班主任跟踪学习根据学员学习情况，反馈学习建议。</span>
          <span>各种信息、资料随时提供。</span>
          <span>一次未过，次年免费重修</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { useCustomerService } from "../../api/common";
const router = useRouter();
const route = useRoute();
const props = defineProps({
  // 全科
  allSubject: {
    default: "15999",
  },
  oldAllSubject: {
    default: "19999",
  },
  // 面试
  anOralQuiz: {
    default: "8999",
  },
  oldAnOralQuiz: {
    default: "11000",
  },
  // 笔试
  penNum: {
    default: "7999",
  },
  oldPenNum: {
    default: "8999",
  },
});
// 点击物品详情
const shopDetail = (status) => {
  if (status) {
    let path = "";
    if (route.name == "country") {
      path = "gwy-shu.png";
    } else if (route.name == "province") {
      path = "sgwy-shu.png";
    } else if (route.name == "career") {
      path = "career-shu.png";
    } else if (route.name == "troops") {
      path = "troops-shu.png";
    }

    router.push({
      path: "/shop-detail",
      query: {
        path: path,
        name: `${setText(route.name)}-VIP高端班`,
        remit: 15999,
      },
    });
    document.documentElement.scrollTop = 0;
  } else {
    useCustomerService();
  }
};
const setText = (text) => {
  const CODE = {
    country: "国考公务员",
    province: "省考公务员",
    career: "事业编考试",
    // troops: "军队文职",
  };
  return CODE[text];
};
</script>

<style lang="scss" scoped>
.high-end-class {
  width: 1200px;
  height: 680px;
  margin: 0 auto;

  .center {
    display: flex;
    // margin-top: -40px;

    .left {
      width: 400px;
      // border: 1px solid #dc3732;
      // background: #fcf9fc;
      background: rgba(237, 236, 237, 0.5);

      .header {
        width: 400px;
        height: 140px;
        // background: #ecc94a;
        background: #de9d3a;
        // background: #dc3732;
        color: #ffffff;

        h2 {
          text-align: center;
          border-bottom: 1px solid #ffffff;
          width: 350px;
          margin: 0 auto;
          height: 57px;
          padding-top: 21px;
          font-size: 32px;
        }

        h3 {
          text-align: center;
          padding-top: 10px;
          font-size: 25px;
          font-weight: normal;
        }
      }

      .contentTop {
        width: 380px;
        border-bottom: 1px solid#dc3732;
        margin: 0 auto;
        height: 90px;
        padding-top: 24px;
        display: flex;
        justify-content: space-between;

        .allSubCont {
          width: 100%;
          text-align: center;
          p {
            color: #dc3732;
            font-size: 24px;
            font-weight: bold;

            span {
              font-size: 38px;
            }
          }

          del {
            color: #666666;
            font-size: 18px;
            padding-left: 5px;
          }
        }

        
      }

      .contentBottom {
        display: flex;
        justify-content: space-around;
        margin: 10px 30px;

        li {
          max-width: 175px;
          text-align: center;

          p {
            font-size: 22px;
            color: #dc3732;
            font-weight: bold;
          }

          del {
            font-size: 16px;
            color: #666666;
          }
        }
      }
      .submit {
          width: 130px;
          height: 42px;
          // background: #dc3732;
          background: #de9d3a;
          color: #ffffff;
          border-radius: 5px;
          font-size: 18px;
          border: none;
          cursor: pointer;
          transition: all 0.3s;
          margin: 10px 5px;
        }

        .submit:hover {
          background: #ff6e49;
        }
      .gwy_body {
        // display: flex;
        margin: 30px 0 0 126px;

        li {
          margin: 0;
          // max-width: 175px;
          margin-right: 18px;
          margin-bottom: 20px;
          // text-align: center;

          p {
            font-size: 22px;
            color: #dc3732;
            font-weight: bold;
            span {
              display: inline-block;
              width: 18px;
              height: 18px;
              background: #405aa5;
              // border-radius: 50%;
              // transform: translateY(2px);
            }
          }

          del {
            font-size: 16px;
            color: #666666;
          }
        }
      }
    }

    .right {
      display: flex;
      margin-left: 10px;

      li {
        width: 200px;
        height: 420px;

        .vip-title {
          text-align: center;
          height: 40px;
          width: 150px;
          margin: 0 auto;
          // border-bottom: 3px solid#dc3732;
          border-bottom: 3px solid #de9d3a;
          font-size: 24px;
          font-weight: bold;
          padding-top: 15px;
        }

        span {
          display: inline-block;
          width: 150px;
          margin: 0 auto;
          padding-left: 30px;
          margin-top: 20px;
          font-size: 16px;
          line-height: 26px;
        }
      }

      li:nth-child(even) {
        background: #efefef;
      }
    }
  }
}
</style>