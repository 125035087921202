<template>
    <div class="selectSchool">
        <h2 class="title">为什么<span>他们</span>都选择金师网？</h2>
        <div class="sBox">
            <div class="sBox1">
                <p class="sItem__h1"><span>十年匠心 深耕教育</span></p>
                <p class="sItem__text">
                    金师网课程专注于考公考编培训，授课宗旨：给知识，建体系；给思想，建逻辑，十年砥砺前行。
                </p>
            </div>
            <div class="sBox2">
                <p class="sItem__h1"><span>自主学习+快乐学习</span></p>
                <p class="sItem__text">
                    金师网拥有讲师多名，打造了线上班主任团队，在线领学，充分发挥线上教学优势，引导学员自主学习、快乐学习。
                </p>
            </div>
            <div class="sBox3">
                <p class="sItem__h1"><span>自主研发核心资料+大数据题库</span></p>
                <p class="sItem__text">
                    由金师网教研团队利用大数据分析系统，分析近三年命题走向及考生丢分题、易错题、必考题，深度预测命题走势，研发配套核心考试资料，并开发大数据支撑的APP-准题库。</p>
            </div>
            <div class="sBox4">
                <p class="sItem__h1"><span>微信领学+云课堂+准题库</span></p>
                <p class="sItem__text">
                    随着移动互联网的普及，金师网紧跟科技潮流，开创微信领学机制，让学员主动学习，利用碎片化时间深度学习。并将视频课程和PC题库，颠覆性升级为云课堂教学和手机APP题库。</p>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
.selectSchool {
    width: 100%;
    height: 618px;
    padding: 80px 0 60px;
    background: url(@/assets/image/select_bgc.png);

    .title {
        font-size: 40px;
        text-align: center;

        span {
            color: #db1d25;
        }
    }

    .sBox {
        width: 52%;
        background: url(@/assets/image/time.png) no-repeat center;
        margin: 48px auto 0;
        position: relative;
        height: 510px;

        margin-left: 50%;
        transform: translateX(-50%);
        margin-left: 50%;
        transform: translateX(-50%);

        div {
            width: 450px;
            position: absolute;
            text-align: left;

            .sItem__h1 {
                font-size: 24px;
                font-weight: 700;
                padding-bottom: 6px;
            }
        }
    }

    .sBox1 {
        top: 10px;
        left: 55px;

        .sItem__h1 {
            color: #f87171;
            text-align: right;
        }
    }

    .sBox2 {
        right: -48px;
        top: 130px;

        .sItem__h1 {
            color: #51d9b1;
        }
    }

    .sBox3 {
        left: -75px;
        top: 250px;

        .sItem__h1 {
            color: #a379ff;
            text-align: right;
        }
    }

    .sBox4 {
        right: 40px;
        bottom: 30px;

        .sItem__h1 {
            color: #67a9f9;
        }
    }
}
</style>