// 公务员
export const publicAffairs = {
    navList: [
        {
            title: '国考公务员',
            tag: 'country'
        },
        {
            title: '省考公务员',
            tag: 'province'
        },
    ],
    title: '公务员考试',
    country: {  // 国考
        imgUrlList: ['ggwyTop.jpg', 'ggwy-bottom-left.png', 'ggwy-bottom-right.png'],
        textWord: [
            {
                subTitle: '热点资讯',
                textList: [
                    {
                        text: '2025公务员考试这几个专业很有优势',
                        params: 'countryConsultOne'
                    },
                    {
                        text: '国家公务员发布公告里要看哪些地方',
                        params: 'countryConsultTwo'
                    },
                    {
                        text: '国考公务员报名注意事项整理必看',
                        params: 'countryConsultThree'
                    },
                    {
                        text: '国考公务员笔试成绩不好怎么办...',
                        params: 'countryConsultFour'
                    },
                    {
                        text: '国考考试资格复审方式有哪些变化',
                        params: 'countryConsultFive'
                    },
                ]
            },
            {
                subTitle: '资料推荐',
                textList: [
                    {
                        text: '国家公务员行测必考的常识知识点',
                        params: 'countryMeansOne'
                    },
                    {
                        text: '2025国家公务员面试容易忽略点',
                        params: 'countryMeansTwo'
                    },
                    {
                        text: '历年的国家公务员行测必背知识点',
                        params: 'countryMeansThree'
                    },
                    {
                        text: '国家公务员考试行测常识判断必考点',
                        params: 'countryMeansFour'
                    },
                    {
                        text: '国考公务员考点应该注意点什么呢',
                        params: 'countryMeansFive'
                    },
                ]
            },
            {
                subTitle: '学员心声',
                textList: [
                    {
                        text: '非常感谢，今年第一次考，完全零基础，老师讲得也比较细，我问的问题很多...',
                        params: 'countryStudentOne'
                    },
                    {
                        text: '金师网专业的让你无话可说，对考试的理解，对各种学员的教学方法，是同行业...',
                        params: 'countryStudentTwo'
                    },
                    {
                        text: '考前资料很不错，而且真正接触后简单易懂，提升很快...',
                        params: 'countryStudentThree'
                    },
                ]
            },
        ]
    },
    province: {  // 省考
        imgUrlList: ['sgwyTop.jpg', 'sgwy-bottom-left.png', 'sgwy-bottom-right.png'],
        textWord: [
            {
                subTitle: '热点资讯',
                textList: [
                    {
                        text: '该如何了解省考公务员相关时政呢',
                        params: 'provinceConsultOne'
                    },
                    {
                        text: '公务员怎么选岗位更容易进面试呢',
                        params: 'provinceConsultTwo'
                    },
                    {
                        text: '公务员怎样区分首次报考人员与非首次',
                        params: 'provinceConsultThree'
                    },
                    {
                        text: '省考公务员政审不过的几个原因是什么',
                        params: 'provinceConsultFour'
                    },
                    {
                        text: '2025公务员政审规则有哪些变化呢',
                        params: 'provinceConsultFive'
                    },
                ]
            },
            {
                subTitle: '资料推荐',
                textList: [
                    {
                        text: '各省考公务员考试易考常识整理（一）',
                        params: 'provinceMeansOne'
                    },
                    {
                        text: '2025河南省公务员知识积累易错点',
                        params: 'provinceMeansTwo'
                    },
                    {
                        text: '省考行测常识判断地理知识易考点',
                        params: 'provinceMeansThree'
                    },
                    {
                        text: '省考公务员常考知识点积累必备总结',
                        params: 'provinceMeansFour'
                    },
                    {
                        text: '各省考公务员易错的考点总结',
                        params: 'provinceMeansFive'
                    },
                ]
            },
            {
                subTitle: '热点资讯',
                textList: [
                    {
                        text: '金师网专业的让你无话可说，对考试的理解，对各种学员的教学方法，是同行业...',
                        params: 'countryStudentOne'
                    },
                    {
                        text: '老师讲的非常详细，重点内容很容易听懂，知识点很详细，学习简单明了，视频...',
                        params: 'countryStudentOne'
                    },
                    {
                        text: '考前资料很不错，而且真正接触后简单易懂，提升很快...',
                        params: 'countryStudentOne'
                    },
                ]
            },
        ]
    },
}
// 事业编、教师编
export const causeOrTeach = {
    navList: [
        // {
        //     title: '教师编考试',
        //     tag: 'teach'
        // },
        {
            title: '事业编考试',
            tag: 'cause'
        },
    ],
    title: '事业编',
    teach: {  // 教师编考试
        imgUrlList: ['jsbTop.jpg', 'jsb-bottom-left.png', 'jsb-bottom-right.png'],
        textWord: [
            {
                subTitle: '热点资讯',
                textList: [
                    {
                        text: '2024教师编制考试基础问题解疑',
                        params: 'teacherConsultOne'
                    },
                    {
                        text: '教师编制考试流程与考情应该注意',
                        params: 'teacherConsultTwo'
                    },
                    {
                        text: '教师编制面试自我介绍的内容有哪些',
                        params: 'teacherConsultThree'
                    },
                    {
                        text: '教师编制政审不通过的原因有哪些呢',
                        params: 'teacherConsultFour'
                    },
                    {
                        text: '谨记教师考编制的整个流程易错点',
                        params: 'teacherConsultFive'
                    },
                ]
            },
            {
                subTitle: '资料推荐',
                textList: [
                    {
                        text: '谨记教师编常考内容知识点整理',
                        params: 'teacherMeansOne'
                    },
                    {
                        text: '2024教师编制笔试重点归纳总结',
                        params: 'teacherMeansTwo'
                    },
                    {
                        text: '教师招聘易考简答题知识点总结',
                        params: 'teacherMeansThree'
                    },
                    {
                        text: '历年教师招聘的易考的知识点总结',
                        params: 'teacherMeansFour'
                    },
                    {
                        text: '教师资格考试之文化素养知识点总结',
                        params: 'teacherMeansFive'
                    },
                ]
            },
            {
                subTitle: '热点资讯',
                textList: [
                    {
                        text: '朋友今年刚考完教师编，考完后说今年的押题真心牛，轻松加愉快的就通过..',
                        params: 'countryStudentOne'
                    },
                    {
                        text: '金师网专业的让你无话可说，对考试的理解，对各种学员的教学方法，是同行业...',
                        params: 'countryStudentOne'
                    },
                    {
                        text: '非常感谢，今年第一次考教编，完全零基础，老师讲得也比较细，我问的问题很多...',
                        params: 'countryStudentOne'
                    },
                ]
            },
        ]
    },
    cause: {  // 事业编考试
        imgUrlList: ['sybTop.jpg', 'syb-bottom-left.png', 'syb-bottom-right.png'],
        textWord: [
            {
                subTitle: '热点资讯',
                textList: [
                    {
                        text: '关于今年事业单位报考你真的了解吗',
                        params: 'causeConsultOne'
                    },
                    {
                        text: '关于事业编考试科目都有什么区别',
                        params: 'causeConsultTwo'
                    },
                    {
                        text: '今年事业编笔试护体考哪些内容呢',
                        params: 'causeConsultThree'
                    },
                    {
                        text: '思考事业编考试有哪些好处与价值',
                        params: 'causeConsultFour'
                    },
                    {
                        text: '2024事业单位报考条件有哪些区别',
                        params: 'causeConsultFive'
                    },
                ]
            },
            {
                subTitle: '资料推荐',
                textList: [
                    {
                        text: '2024事业编公基必背知识点50条收藏',
                        params: 'hotFour'
                    },
                    {
                        text: '事业编公共基础知识高频考点梳理必背',
                        params: 'causeMeansTwo'
                    },
                    {
                        text: '事业单位考试部分常识知识点梳理',
                        params: 'causeMeansThree'
                    },
                    {
                        text: '事业单位公共基础知识重点',
                        params: 'causeMeansFour'
                    },
                    {
                        text: '事业单位考试管理常识积累',
                        params: 'causeMeansFive'
                    },
                ]
            },
            {
                subTitle: '热点资讯',
                textList: [
                    {
                        text: '考前资料很不错，而且真正接触后简单易懂，提升很快...',
                        params: 'countryStudentOne'
                    },
                    {
                        text: '去年考试报名前两个月开始准备，报课，买视频，冲刺，到考试前几乎没有怎么休息...',
                        params: 'countryStudentOne'
                    },
                    {
                        text: '很喜欢金师的教学，很有针对性，老师很清楚地知道哪些地方是你的薄弱点，对考试...',
                        params: 'countryStudentOne'
                    },
                ]
            },
        ]
    },
}

// 军队文职
export const militaryCivilian = {
    navList: [
        {
            title: '军队文职',
            tag: 'military'
        }
    ],
    title: '军队文职',
    military: {  // 国考
        imgUrlList: ['jdwzTop.jpg', 'jdwz-bottom-left.png', 'jdwz-bottom-right.png'],
        textWord: [
            {
                subTitle: '热点资讯',
                textList: [
                    {
                        text: '报考今年军队文职的建议整理总结',
                        params: 'militaryConsultOne'
                    },
                    {
                        text: '军队文职笔试注意事项有哪些要避开',
                        params: 'militaryConsultTwo'
                    },
                    {
                        text: '2023军队文职岗位报考注意事项',
                        params: 'militaryConsultThree'
                    },
                    {
                        text: '军队文职技能岗面试考什么内容呢',
                        params: 'militaryConsultFour'
                    },
                    {
                        text: '军队文职限制性报考条件整理必备',
                        params: 'militaryConsultFive'
                    },
                ]
            },
            {
                subTitle: '资料推荐',
                textList: [
                    {
                        text: '军队文职备考之历史知识点易考总结',
                        params: 'militaryMeansOne'
                    },
                    {
                        text: '军队文职公共科目特殊考点的记忆',
                        params: 'militaryMeansTwo'
                    },
                    {
                        text: '军队文职每年必考考点重点总结',
                        params: 'militaryMeansThree'
                    },
                    {
                        text: '2023军队文职必考时政考点背诵',
                        params: 'militaryMeansFour'
                    },
                    {
                        text: '军队文职备考必看之古代国防的兴衰',
                        params: 'militaryMeansFive'
                    },
                ]
            },
            {
                subTitle: '热点资讯',
                textList: [
                    {
                        text: '我今年报名了金师网的军队文职课程，听了十多节课了，听课之后觉得还是很不错',
                        params: 'countryStudentOne'
                    },
                    {
                        text: '老师真的挺不错的，很负责，确实获益匪浅。本来很少评论的，通过军队文职了...',
                        params: 'countryStudentOne'
                    },
                    {
                        text: '金师网很厉害，老师根据自己的理解记忆还有学习现状进行讲课，很到位，希望加...                        ',
                        params: 'countryStudentOne'
                    },
                ]
            },
        ]
    },
}