<template>
  <div class="application">
    <p class="title">
      {{ time }}年{{ name }}考试
      <span>报名条件</span>
    </p>
    <ul class="use_list">
      <li>
        <img src="@/assets/application/icon1.png" alt="">
        <p class="use_title">年龄要求</p>
        <span>国家公务员考试对考生年龄有一定要求，要求考生年龄在18至...</span>
        <p class="use_button">查看更多</p>
      </li>
      <li>
        <img src="@/assets/application/icon2.png" alt="">
        <p class="use_title">学历要求</p>
        <span>考生取得国家承认的大专及以上学历即可报名符合条件的职位，自学考试、成人教育均...</span>
        <p class="use_button">查看更多</p>
      </li>
      <li>
        <img src="@/assets/application/icon3.png" alt="">
        <p class="use_title">专业要求</p>
        <span>专业要求是限制考生报考的最大门槛，考生报名参加公务员考试必须报考专业一致的，所谓专业一致为...</span>
        <p class="use_button">查看更多</p>
      </li>
      <li>
        <img src="@/assets/application/icon4.png" alt="">
        <p class="use_title">其他要求</p>
        <span>历年国考部分职位对考生的户籍和基层工作经历有所要求，户籍限制会体现在...</span>
        <p class="use_button">查看更多</p>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
const name = computed(() => {
  const CODE = {
    country: "国考公务员",
    province: "省考公务员",
    career: "事业编考试",
    // troops: "军队文职",
  };
  return CODE[route.name];
});
const time = computed(() => {
  const CODE = {
    country: "2025",
    province: "2025",
    career: "2024",
    // troops: "军队文职",
  };
  return CODE[route.name];
});
</script>

<style lang="scss" scoped>
.application {
  width: 100%;
  background-color: #f4f4f4;
  padding: 40px 0 75px 0;
  margin-top: -10px;

  .title {
    text-align: center;
    margin-bottom: 55px;
    padding-top: 9px;
    letter-spacing: 3px;
    font-size: 38px;
    color: #333333;
    font-weight: bold;

    span {
      color: #fa2e2e;
    }
  }

  .use_list {
    width: 63%;
    margin-left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -10px;

    li {
      position: relative;
      width: 19.3%;
      height: 378px;
      background-color: #ffffff;
      box-shadow: 0 2px 20px 0 #f1c9c6;
      padding: 0 24px;

      img {
        display: block;
        width: 88px;
        margin: 35px auto 0;
      }

      .use_title {
        font-size: 22px;
        text-align: center;
        margin-top: 16px;
        font-weight: bold;
      }

      span {
        display: inline-block;
        font-size: 18px;
        color: #333333;
        text-align: justify;
        text-align-last: left;
        line-height: 30px;
        margin-top: 13px;
      }

      .use_button {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 167px;
        height: 38px;
        line-height: 38px;
        background-color: #cb463b;
        color: #ffffff;
        text-align: center;
        font-size: 20px;
        border-radius: 5px;
      }
    }
  }
}
</style>