<template>
  <!-- 弹窗 -->
  <!-- <use-dialog></use-dialog> -->

  <nav>
    <Headers></Headers>
  </nav>
  <!-- <keep-alive> -->
  <router-view class="animate__animated animate__fadeIn" />
  <!-- </keep-alive> -->
  <footerVue></footerVue>
  <!-- 左右悬浮 -->
  <suspend-left></suspend-left>
  
  <suspend-right></suspend-right>
</template>

<script>
// L15279583867
// import useDialog from '@/components/use-dialog.vue';

import Headers from "@/components/header-web.vue";
import footerVue from "@/components/footer-vue.vue";
import suspendLeft from "@/components/suspend/suspend-left.vue";
import suspendRight from "@/components/suspend/suspend-right.vue";
export default {
  name: "APP",
  data() {
    return {};
  },
  methods: {},
  components: {
    // useDialog,
    Headers,
    footerVue,
    suspendLeft,
    suspendRight,
  },
};
</script>

<style lang="scss">
#app {
  min-width: 1200px !important;
}
#LRMINIWIN {
  width: 500px !important;
}
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

p,
li,
a,
span,
label {
  cursor: default;
}

.centerBody {
  width: 1205px;
  margin-left: 50%;
  transform: translate(-50%);
}

.homeBanner {
  width: 100%;
  // min-height: 594px;
  min-height: 700px;
  cursor: pointer;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  outline: none;
  margin: 0px auto;
  position: relative;
  z-index: 1;
  background-size: cover;
  margin-bottom: 12px;
  margin-top: 85px;
}

.zoom_img {
  transition-duration: 0.5s;
}

.zoom_img:hover {
  transform: scale(1.4);
}
// 动画
@keyframes swtCs {
  0% {
    margin-left: 0px;
  }

  25% {
    margin-left: -15px;
  }

  50% {
    margin-left: 0px;
  }

  75% {
    margin-left: 15px;
  }

  100% {
    margin-left: 0px;
  }
}
</style>
