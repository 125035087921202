<template>
    <div class="question">
        <p class="title"><span class="notice">通知</span>{{ name }}考试各省份报考<span class="red_text">条件解读</span></p>
        <p class="top_tips tips">从2013年国考报名审核通过人数突破150万大关、平均竞争比例501以上之后，2017、2018年势头有所回转审核人数低</p>
        <p class="tips">于150万，最终人数比例低于501，而2025年更是……</p>
        <ul class="city_list">
            <li v-for="item in provinceList" :key="item">{{ item }}</li>
        </ul>
    </div>
</template>

<script setup>
import { computed, reactive } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
const name = computed(() => {
    const CODE = {
        country: "国考公务员",
        province: "省考公务员",
        career: "事业编考试",
        // troops: "军队文职",
    };
    return CODE[route.name];
});
const provinceList = reactive(['北京', '上海', '天津', '重庆', '黑龙江', '吉林', '宁夏', '陕西', '山西', '山东', '河北', '河南', '广东', '广西', '四川', '云南', '贵州', '福建', '辽宁', '内蒙古', '新疆', '甘肃', '浙江', '海南', '湖北', '湖南', '安徽', '江苏', '青海', '更多']);

</script>

<style lang="scss" scoped>
.question {
    padding: 50px 0;
    .title {
        font-size: 38px;
        color: #333333;
        font-weight: bold;
        background: url(@/assets/question/title_bg4.png) no-repeat 50% 0%;
        background-size: 154px 25px;
        padding-top: 11px;
        letter-spacing: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .red_text {
            color: #fa2e2e;
        }

        .notice {
            padding: 2px 24px;
            background-color: #fa2e63;
            color: #ffffff;
            font-size: 28px;
            font-weight: bold;
            border-radius: 50px;
            margin-right: 13px;
            text-align: center;

        }
    }
    .top_tips {
        margin-top: 30px;
    }
    .tips {
        font-size: 20px;
        color: #333333;
        line-height: 40px;
        text-align: center;
    }
    .city_list {
        width: 62%;
        margin-left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 50px;
        li {
            width: 7.5%;
            margin-right: 20px;
            background: #0063d9;
            height: 34px;
            line-height: 34px;
            text-align: center;
            color: #000;
            background-color: #f4f4f4;
            border: 1px solid #c9c9c9;
            margin: 0 25px 29px 0;
            cursor: pointer;
        }
        li:nth-child(10n){
            margin-right: 0;
        }
        li:nth-child(1), li:nth-child(6), li:nth-child(9), li:nth-child(15){
            background-color: #0063d9;
            color: #ffffff;
            position: relative;
        }
        li:hover {
            background-color: #0063d9;
            color: #ffffff;
            position: relative;
        }
    }

}
</style>