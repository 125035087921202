<template>
    <div class="suspend-right">
        <img src="@/assets/image/swtlogo.png" class="topImage" alt="">
        <ul class="teacherList">
            <li v-for="(item, index) in teacherList" :key="index" @click="useCustomerService">
                <img :src="require(`@/assets/image/${item.leftImage}`)" class="leftImage" alt="">
                <p>{{ item.name }}</p>
                <img :src="require(`@/assets/image/${item.rightImage}`)" class="rightImage" alt="">
            </li>
        </ul>

        <el-backtop :right="100" :bottom="100"></el-backtop>
    </div>
</template>

<script setup>
import { useCustomerService } from '@/api/common.js'
import { reactive } from "vue";

const teacherList = reactive([
    {
        name: '周老师',
        leftImage: 'zhang.png',
        rightImage: 'hot-image.png'
    },
    {
        name: '宋老师',
        leftImage: 'yu.png',
        rightImage: 'hot-image.png'
    },
    {
        name: '钱老师',
        leftImage: 'chao.png',
        rightImage: 'hot-image.png'
    },
    {
        name: '荣老师',
        leftImage: 'zhao.png',
        rightImage: 'hot-image.png'
    },
    {
        name: '刘老师',
        leftImage: 'hao.png',
        rightImage: 'hot-image.png'
    },
    {
        name: '李老师',
        leftImage: 'li.png',
        rightImage: 'hot-image.png'
    },
    {
        name: '金老师',
        leftImage: 'liu.png',
        rightImage: 'hot-image.png'
    },
    {
        name: '韩老师',
        leftImage: 'zhou.png',
        rightImage: 'hot-image.png'
    },
]);
</script>

<style lang="scss" scoped>
@keyframes identifier {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
.suspend-right {
    width: 154px;
    height: 595px;
    background: rgba(255, 255, 255, 0.5);
    position: fixed;
    top: 163px;
    right: 33px;
    // top: 163px;
    // left: 40px;
    border-radius: 33px 33px 13px 13px;
    z-index: 10;
    box-shadow: 1px 5px 7px 1px #ccc7c7;

    .topImage {
        width: 101px;
        height: 133px;
        margin-left: 52%;
        transform: translate(-50%, -30px);
    }

    .teacherList {
        margin-top: -30px;

        li {
            margin: 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            .leftImage {
                width: 30px;
            }

            .rightImage {
                width: 41px;
                height: 47px;
                margin-bottom: 8px;
                animation: identifier 1.5s linear infinite;
            }

            p {
                font-size: 17px;
                color: #333;
                cursor: pointer;
            }
        }

        li:hover {
            p {
                color: red;
                font-weight: bold;
            }
        }
    }
}</style>