// import swtEvent from '@/api/swtEvent.js';
import router from '@/router/index'
// 商务通接入
// const swtPath = `https://plt.zoosnet.net/LR/Chatpre.aspx?id=PLT84526414&cid=81fe51fd8af04fd39eb58aedc17edc42&lng=cn&sid=3f1155128a9a4e86844c8e99b8594766`

// 上一个页面打开路径
// const referrer_path = document.referrer;
// 主路径
export function useCustomerService() {
    console.log('123')
    window.$openSwt.open();
}

// 跳转内容详情页
export function useContentDetail(item) {
    const routeUrl = router.resolve({
        name: 'public-consultation',
        params: {
            id: item.params
        }
    })
    window.open(routeUrl.href, '_blank')
}

// 订单号 
export function useOrderCode() {
    const date = new Date();
    let [year, month, day, hours, minute, second] = [date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()]
    month = month >= 10 ? month.toString() : 0 + month.toString();
    day = day >= 10 ? day.toString() : 0 + day.toString();
    hours = hours >= 10 ? hours.toString() : 0 + hours.toString();
    minute = minute >= 10 ? minute.toString() : 0 + minute.toString();
    second = second >= 10 ? second.toString() : 0 + second.toString();
    return year + month + day + hours + minute + second;
}
// 商城转换多少元
export function useIsDrop(item) {
    return item.indexOf('.') == -1 ? '.00元' : '元'
}

// 动画
export function offsetTop(elements) {
    let top = elements.offsetTop;
    let parent = elements.offsetParent;
    while (parent != null) {
        top += parent.offsetTop;
        parent += parent.offsetParent;
    }
    return top;
}